<template>
  <div>
    <loading
      v-model:active="is_loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <article class="message is-warning is-small" v-if="error_message">
      <div class="message-body">
        {{ error_message }}
      </div>
    </article>
    <div class="content" v-if="is_allow_action">
      <ReceptionHeader>
        <ActionFlow
          select_status="active"
          post_status="active"
          complete_status="active"
        />
      </ReceptionHeader>
      <article class="message is-success is-small" v-if="success_message">
        <div class="message-body">
          {{ success_message }}
        </div>
      </article>
      <div v-if="!is_done">
        スマリ宅配ボックス発送をご利用される場合は<b>発送受付</b>を押してください。
        <div class="btn-wrapper">
          <button
            class="button is-rounded is-medium btn-submit"
            @click="createReception"
            v-if="is_allow_action"
          >
            発送受付
          </button>
        </div>
        <p>
          ※宅配ボックス発送対応エリアは<a href="https://smari.io/terms#area" target="_blank" class="area-link is-underlined">こちら</a>
        </p>
        <p>
          発送しない方はブラウザを閉じてください。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import ServiceRepository from "../repositories/ServiceRepository"
import MailService from "../services/MailService"
import { Auth } from "aws-amplify"
import Loading from "vue-loading-overlay"
import ActionFlow from "../components/elements/ActionFlow.vue"
import ReceptionHeader from "../components/ReceptionHeader.vue"

export default {
  name: "Reception",
  data() {
    return {
      is_loading: false,
      is_allow_action: false,
      is_done: false,
      error_message: "",
      success_message: "",
      service: {
        code: "",
        name: "",
        amount: "",
        is_payment_enabled: "",
      }
    }
  },
  components: {
    Loading,
    ActionFlow,
    ReceptionHeader,
  },
  async beforeMount() {
    this.is_loading = true
    if (
      this.$route.query.sr === undefined ||
      this.$route.query.trc === undefined
    ) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      this.is_loading = false
      return
    }
    try {
      const service = await ServiceRepository.findByCode(this.$route.query.sr)
      if (service.is_payment_enabled) {
        this.$router.push({
          path: "/payment-reception",
          query: this.$route.query,
        })
      }
      this.service = service
    } catch (error) {
      if (error.response) {
        if (error.response.status === 406) {
          this.error_message =
            "お客様がご利用のEC事業者様はスマリ宅配ボックス発送には対応しておりません。ローソン店舗をはじめとしたスマリボックスから発送をお願いいたします。"
          return
        }
      }
      this.error_message =
        "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
      return
    } finally {
      this.is_loading = false
    }

    this.is_allow_action = true
  },
  methods: {
    async createReception() {
      this.is_loading = true
      try {
        const values = {
          sr_code: this.$route.query.sr,
          service_trc_no: this.$route.query.trc,
          optional_service_no: this.$route.query.opt,
          service_name: this.service.name,
        }
        const reception = await DeliveryboxReceptionRepository.create(values)
        this.$route.query.token = reception.token
        this.$router.push({
          path: "/store/select",
          query: this.$route.query,
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.error_message = "既に発送受付処理が完了している商品です。ご確認ください。"
          return
        }
        this.error_message =
          "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
      } finally {
        this.is_loading = false
      }
    },
  },
}
</script>

<style scoped>
.btn-wrapper {
  text-align: center;
  padding: 50px 0;
}

.area-link {
  color: #092c0b;
}
</style>
