import { Auth, API } from "aws-amplify"

export default {
  findByCode: async (code) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
    }
    const result = await API.get("smariDeliveryboxAPI", `/service/${code}`, init)

    return result
  },
}
